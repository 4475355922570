<template>
  <section>
  <div @click="selectValue" class="dropdownPopupList">
    <div>
      {{ labelling }}
    </div>
    <div
      v-if="item.id !== null"
      @click.prevent.stop="favoriteSelected(item)"
      class="dropdownPopupListProp"
    >
      <StarIcon v-if="isLogged" :boolean="favorite" />
    </div>
  </div>
  <FavoritesPopup
      :popup="popup"
      :favorite="item"
      @closePopup="closePopup"
      @confirmPopup="deleteSelected"
      :key="item.id"
    />
  </section>
</template>

<script>
import StarIcon from '@/components/icons/Star';
import FavoritesPopup from '@/components/base-components/Favorites/Popup';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SelectComponentDropdownNode',
  components: {
    StarIcon,
    FavoritesPopup,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    propForValue: {
      type: String,
      default: 'id',
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      favorite: false,
      popup: false,
    };
  },
  computed: {
    ...mapGetters({
      getFavorites: 'getFavorites',
    }),
    labelling() {
      return this.item.id === null ? '' : this.item.name;
    },
    isLogged() {
      return this.$store.getters.isLogged;
    },
  },
  mounted() {
    this.favorite = this.getFavorites.some(
      (favorite) => favorite.favorite_id === this.item.id && favorite.favorite_type === "category"
    );
  },
  methods: {
    ...mapActions([
      'POST_ADD_FAVORITE',
      'GET_DELETE_FAVORITE',
      'GET_USER_FAVORITES',
    ]),

    selectValue() {
      this.$emit('selectedValue', this.item);
    },
    deletePopup() {
      this.popup = !this.popup;
    },
    closePopup() {
      this.popup = false;
    },
    deleteSelected() {
      this.GET_DELETE_FAVORITE({
        favorite_type: 'category',
        id: this.item.id,
      });
      this.closePopup();
    },
    favoriteSelected(item) {
      this.favorite = !this.favorite;
      if (!this.favorite) {
        this.deletePopup()
      } else if (this.favorite) {
        const payload = {
          favorite_id: item.id,
          favorite_type: 'category',
          name: item.name,
        };
        this.POST_ADD_FAVORITE(payload);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/css/colors';
.dropdownPopupList {
  display: flex;
  text-align: start;
  min-height: 58px;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid $text-tertiary-middle;
  
  // box-shadow: inset 0 0 0 1px $text-tertiary-middle;
}
.dropdownPopupListProp {
  margin-left: auto;
  margin-right: 1rem;
}
</style>
