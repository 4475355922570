<template>
  <label class="select-wrapper" v-click-outside="closeDropdown">
    <span v-if="label">
      {{ label }}
    </span>
    <div
      @click="dropdownClick"
      :class="{
        'custom-selector': !disabled,
        'custom-selector-disabled': disabled,
      }"
    >
      <div class="custom-selector-label">{{ selected.name }}</div>
      <div class="custom-selector-dropdown">
        <ChevronIcon :width="24" :height="24" :rotation="rotation" />
      </div>
    </div>
    <div v-if="dropdown" class="dropdownPopup">
      <SelectComponentDropdownNode
        :item="item"
        :propForValue="propForValue"
        v-for="item in data"
        :key="item[propForValue]"
        :label="label"
        @selectedValue="selectValue"
      />
    </div>
  </label>
</template>

<script>
import ChevronIcon from '@/components/icons/Chevron';
import SelectComponentDropdownNode from '@/components/base-components/Favorites/SelectComponentDropdownNode';

export default {
  name: 'SelectComponent',
  components: {
    ChevronIcon,
    SelectComponentDropdownNode,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    selectedValue: {
      type: [String, Number, Array],
      default: null,
    },
    propForDisplay: {
      type: String,
      default: 'name',
    },
    propForValue: {
      type: String,
      default: 'id',
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dropdown: false,
      selected: {
        name: '',
        id: null,
      },
      favorite: false,
    };
  },
  watch: {
    selectedValue(newValue, oldValue) {
      if (newValue === null) {
        this.selected = { name: '', id: null };
      }
    },
    data(newValue) {
      if (newValue.length !== 0 && this.selectedValue) {
        let index = this.data.findIndex((d) => d.id === this.selectedValue);
        if (index !== -1) {
          this.selected = {
            name: this.data[index].name,
            id: this.selectedValue,
          };
        }
      }
    },
  },
  computed: {
    rotation() {
      return this.dropdown ? 'top' : 'bottom';
    },
  },
  methods: {
    dropdownClick() {
      if (!this.disabled) {
        this.dropdown = !this.dropdown;
      }
    },
    selectValue(value) {
      this.selected = value;
      this.$emit('update:selectedValue', value.id);
      this.dropdown = false;
    },
    closeDropdown() {
      if (this.dropdown) {
        this.dropdown = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/css/colors';
.select-wrapper {
  display: flex;
  border-radius: 0.3rem;
  // border: 1px solid $text-tertiary-middle;
  //box-shadow:  inset 0 0 0 1px $text-tertiary-middle;
  align-items: center;
  justify-content: center;
  position: relative;
  span {
    padding: 0.5rem 1rem;
    border-bottom-left-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    width: 155px;
    background: $text-tertiary-light;
    font-style: normal;
  }
  // select {
  //   min-width: 10rem;
  //   border: none;
  // }
}

.custom-selector-label {
  font-size: 0.9 rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: start;
  font-style: normal;
  text-transform: none;
}

.custom-selector {
  font-style: normal;
  display: flex;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  color: #495057;
  vertical-align: middle;
  background: white;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-selector-disabled {
  font-style: normal;
  display: flex;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  color: #495057;
  vertical-align: middle;
  background: var(--text-tertiary-light);
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-selector-dropdown {
  margin-left: auto;
}
.selectionContainer {
  position: relative;
  width: 100%;
  border: none;
}
.dropdownPopup {
  background: white;
  position: absolute;
  top: 2.3rem;
  z-index: 10;
  max-height: 100vh;
  overflow-y: auto;
  left: 0;
  right: 0;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  overflow: hidden;
  box-shadow: 0 0 2px 0 $text-tertiary-middle;
}
</style>
